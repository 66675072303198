<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter
          :typeList="typeList"
          :sortConfig="sortConfig"
          @handleSearchParams="handleSearch"
        ></top-filter>
      </div>
      <div class="pro-list">
        <div class="item" v-for="(t, i) in dataList" :key="i">
          <div class="product">
            <img :src="t.pictureUrl" class="img" />
            <div class="info">
              <div class="title">
                <div class="type">{{t.insuranceName}}</div>
                <div class="icon">{{insuranceTypeDic[t.insuranceType]}}</div>
              </div>
              <div class="desc">{{t.insuranceDesc}}</div>
            </div>
            <div class="more" @click="handleDetailClick(t.id)">查看详情</div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page.pageNum"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'insureList',
  components: {
    TopFilter
  },
  data() {
    return {
      insuranceTypeDic: { 1: "人身保险", 2: "财产保险", 3: "责任保险" },
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      current: 0,
      keyword: "",
      typeList: [
        {
          name: '保险机构',
          code: 'org',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '中国太平',
              val: 1,
            },
            {
              name: '中国人民保险',
              val: 2,
            },
            {
              name: '中航安盟保险',
              val: 3,
            },
            {
              name: '中国大地保险',
              val: 4,
            },
            {
              name: '中国平安',
              val: 5,
            },
            {
              name: '阳光保险财险',
              val: 6,
            },
            {
              name: '锦泰保险',
              val: 7,
            },
            {
              name: '中国人寿',
              val: 8,
            },
            {
              name: '太平洋保险',
              val: 9,
            }
          ]
        },
        {
          name: '保险险种',
          code: 'insuranceType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '人身保险',
              val: 1,
            },
            {
              name: '财产保险',
              val: 2,
            },
            {
              name: '责任保险',
              val: 3,
            },
            {
              name: '信用保证保险',
              val: 4,
            },
            {
              name: '火灾保险',
              val: 5,
            },
            {
              name: '海上保险',
              val: 6,
            },
            {
              name: '货物运输保险',
              val: 7,
            },
            {
              name: '运输工具保险',
              val: 8,
            },
            {
              name: '工程保险',
              val: 9,
            },
            {
              name: '损失保险',
              val: 10,
            },
            {
              name: '盗窃保险',
              val: 11,
            },
            {
              name: '农业保险',
              val: 12,
            },
            {
              name: '责任保险',
              val: 13,
            },
            {
              name: '公众责任保险',
              val: 14,
            }
          ]
        },
      ],
      sortConfig: [
        {
          name: '发布时间',
          code: 1,
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.technologyFinance.insuranceList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/technologyFinance/insureDetail", query: { id: id } });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    .product {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      height: 150px;
      border: 1px solid #ececec;
      align-items: center;
      .img {
        width: 197px;
        height: 83px;
      }
      .info {
        flex: 0.8;
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
          flex-direction: row;
          .type {
            font-weight: 700;
            padding: 10px 20px;
          }
          .name {
            font-size: 14px;
            font-weight: 200;
            padding: 10px 20px;
          }
          .icon {
            font-size: 14px;
            color: #f1af53;
            padding: 10px 20px;
            //border: 2px solid #F1AF53;
            //height: 15px;
            //border-radius: 0 25px 0 25px;
          }
        }

        .desc {
          padding: 0 20px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .more {
        display: inline-block;
        background-color: #0046c0;
        color: #fff;
        padding: 8px 15px;
        border-radius: 6px;
        margin: 10px 20px 20px;
        cursor: pointer;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
